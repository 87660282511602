
import { Vue, Options, prop } from "vue-class-component";

class Props {
  title = prop<string>({
    default: "",
    type: String
  });
  onClose = prop<Function>({
    default: null,
    type: Function
  });
}
@Options({
  name: "ModalOtherMethod"
})
export default class extends Vue.with(Props) {}
